import classNames from "classnames";
import React from "react";
import BackButton from "../../components/BackButton/BackButton";
import Button from "../../components/Button/Button";
import FluidImage from "../../components/FluidImage/FluidImage";
import HeroSection from "../../components/HeroSection/HeroSection";
import fadeInSlide from 'utils/animations/fade-in-slide';
import { addRedPeriod } from 'utils/helpers';

import "./HeroCta.scss";

const HeroCta = ({
  cta,
  ctaVariant,
  title,
  description,
  rightImage,
  overlapSection,
  backDetails,
  ...props
}) => {
  const [ contentRef ] = fadeInSlide('x', -25);
  const [ imageRef ] = fadeInSlide('x', 25);

  const hero = (rightImage?.useImage)
    ? <FluidImage image={rightImage.useImage().right.imgData} /> :
    (rightImage?.name)
      ? <img src={rightImage.name} /> : null;

  return (
    <HeroSection
      className={classNames("HeroCta", { overlapSection })}
      {...props}
    >
      <div className="container">
        <div className="row align-items-sm-start align-items-md-center">
          <div className="col-lg-6">
            <div ref={contentRef} className="alpha0">
              {backDetails?.uri && (
                <BackButton
                  to={backDetails.uri}
                  variant="light"
                >{`Back to ${backDetails.title}`}</BackButton>
              )}
              {/* <h3 className="Text--light" dangerouslySetInnerHTML={{ __html: addRedPeriod(title) }} /> */}
              <h3 className="Text--light" dangerouslySetInnerHTML={{ __html: title }} />
              <div className="HeroCtaText Text--light">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
            {!cta?.url ? null : (
              <Button
                to={new URL(cta.url).pathname}
                variant={ctaVariant}
                target={cta.target}
              >
                {cta.title}
              </Button>
            )}

          </div>
          <div className="col-lg-6">
            <div ref={imageRef} className="SectionRightImage alpha0">
              {hero}
            </div>
          </div>
        </div>
      </div>
    </HeroSection>
  );
};

export default HeroCta;
